import React, { useEffect, useState } from 'react';
import { isDev } from '../../utils/Env';
import { useAuth } from '../auth/AuthProvider';

const allowedOrigin =
    isDev || window.location.host === 'accounts-d.bikiran.com'
        ? ['http://localhost:3000']
        : [
              'https://www.bikiran.com',
              'https://admins.bikiran.com',
              'https://push.bikiran.com',
              'https://console.bikiran.com'
          ];

function CrossPage() {
    const { authInfo, signOut } = useAuth();
    const [receivedData, setReceivedData] = useState({ data: null, origin: '' });

    console.log(receivedData);

    // --Send  Ready Message
    useEffect(() => {
        window?.parent?.postMessage({ action: 'ready' }, '*');
    }, []);

    useEffect(() => {
        // --Sent Login Info
        if (
            authInfo.loading === false &&
            receivedData.action === 'login-required' &&
            allowedOrigin.find((item) => item === receivedData.origin)
        ) {
            window?.parent?.postMessage({ ...authInfo, action: 'login' }, receivedData.origin);
        }
    }, [authInfo, receivedData]);

    useEffect(() => {
        // --Sent Logout Info
        if (
            authInfo.loading === false &&
            receivedData.action === 'logout-required' &&
            allowedOrigin.find((item) => item === receivedData.origin)
        ) {
            signOut()
                .then((data) => {
                    window?.parent?.postMessage({ ...data, action: 'logout' }, receivedData.origin);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [authInfo, receivedData, signOut]);

    useEffect(() => {
        const handelMessageListener = ({ data, origin }) => {
            console.log(data, origin);
            setReceivedData({ ...data, origin });
        };

        window.addEventListener('message', handelMessageListener);

        return () => {
            window.removeEventListener('message', handelMessageListener);
        };
    }, []);

    return <div>CrossPage</div>;
}

export default CrossPage;
